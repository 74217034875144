// ErrorDialog.js
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ErrorDialog = ({ isOpen, message, onClose }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: isSmallScreen ? '0px' : '8px',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          backgroundColor: '#f44336', // Red background for error
          color: '#FFFFFF',
          fontWeight: 'bold',
          fontSize: '1.5rem',
          padding: isSmallScreen ? '12px' : '16px',
        }}
      >
        <ErrorOutlineIcon fontSize="large" />
        Error
      </DialogTitle>
      <DialogContent sx={{ padding: isSmallScreen ? '16px' : '24px' }}>
        <DialogContentText
          sx={{
            fontSize: isSmallScreen ? '1rem' : '1.2rem',
            color: '#373A40',
            textAlign: 'center',
          }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: isSmallScreen ? '8px' : '16px', justifyContent: 'center' }}>
        <Button
          onClick={onClose}
          variant="contained"
          sx={{
            backgroundColor: '#d32f2f',  // Dark red button
            color: '#FFFFFF',
            fontSize: isSmallScreen ? '0.8rem' : '0.9rem',
            padding: '6px 16px',
            minWidth: isSmallScreen ? '80px' : '100px',  // Smaller button size
            '&:hover': {
              backgroundColor: '#9a0007',
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
