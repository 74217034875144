import React from 'react';
import SubBoard from './sub-board.js';
import './ultimate-tic-tac-toe.css';

const Board = ({ boards, onCellClick, boardWinners }) => {
  return (
    <div className="board">
      {boards.map((cells, index) => (
        <SubBoard
          key={index}
          tiles={cells}
          onClick={(cellIndex, value) => onCellClick(index, cellIndex, value)}
          isLocked={!!boardWinners[index]}
          winner={boardWinners[index]}
        />
      ))}
    </div>
  );
};

export default Board;
