import React, { useContext } from "react";
import { useState } from "react";
import Graph from 'react-graph-vis';
import {
    Grid,
    Paper,
    Typography,
    Divider,
    Stack,
    Snackbar,
    Alert, Box, Button
} from "@mui/material";
import { v4 as uuid } from 'uuid';
import FigureSelection from "./hackenbush-figures";
import './hackenbush.css'
import { useEffect } from "react";
import NavBar from "../../navbar/navbar";
import GameResultDialog from './game-result-dialog'
import { GameModeContext } from "./Provider/GameModeProvider";
import LoadingIcon from "./LoadingIcon";
import { useNavigate } from 'react-router-dom';
import { HowToPlay } from "./how-to-play";

const GameStatus = Object.freeze({
    Ready: Symbol("Ready"),
    Start: Symbol("Start"),
    End: Symbol("End")
});

const useGameMode = () => useContext(GameModeContext);
export default function Hackenbush() {
    const navigate = useNavigate();
    const { selectedMode, handleModeSelect } = useGameMode();

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [openWarning, setopenWarning] = useState(false);
    const [openhowToPlayDialog, setHowToPlayDialogVisibility] = useState(false);

    const [gameStatus, setGameStatus] = useState(GameStatus.Ready);
    const [currentPlayer, setCurrentPlayer] = useState('red');
    const [winner, setWinner] = useState();
    const [playerTurn, setPlayerTern] = useState('HUMAN')


    useEffect(() => {
        const redEdgeCount = edges.filter(x => x.color === "red").length;
        const blueEdgeCount = edges.filter(x => x.color === "blue").length;
        if (gameStatus === GameStatus.Start) {
            if (currentPlayer === "red" && redEdgeCount === 0) {
                setWinner('Blue')
                setEdges([]);
                setNodes([]);
                setGameStatus(GameStatus.End);
                handleModeSelect('')
            } else if (currentPlayer === "blue" && blueEdgeCount === 0) {
                setWinner('Red')
                setEdges([]);
                setNodes([]);
                setGameStatus(GameStatus.End);
                handleModeSelect('')
            }
        }
    }, [edges, currentPlayer]);

    useEffect(() => {
        if (selectedMode === 'MACHINE') {
            if (gameStatus === GameStatus.Start && playerTurn === 'MACHINE') {
                setTimeout(() => playMachineNextMove(), 1000)
            }
        }
    }, [playerTurn])

    const playMachineNextMove = () => {
        //Temporary solution
        const redEdges = edges.filter(x => x.color === "blue");
        if (redEdges?.length > 1) {
            removeDisconnectedEdges(redEdges[Math.floor(Math.random() * redEdges.length)].id);
            setCurrentPlayer(currentPlayer === "red" ? "blue" : "red");
            setPlayerTern('HUMAN')
        }
        else if (redEdges?.length === 1) {
            removeDisconnectedEdges(redEdges[0].id);
            setCurrentPlayer(currentPlayer === "red" ? "blue" : "red");
            setPlayerTern('HUMAN')
        }
    }

    const isTerminateState = () => {
        const redEdgeCount = edges.filter(x => x.color === "red").length;
        return redEdgeCount === 0;
    }
    // Not completed
    const findNextBestMove = () => {
        const edgesList = getAllPossibleMoves(edges, "blue");

        const floorNodes = nodes.filter(node => node.y === 400).map(node => node.id);

        const floorEdges = edgesList.filter(edge => floorNodes.includes(edge.from) || floorNodes.includes(edge.to));

        const points = 0;
        floorEdges.filter(edge => {
            const frontNode = edge.to
            const backNode = edge.from
            const linkedEdgesToFrontNode = edges.filter(edge => edge.from === frontNode || edge.to === frontNode)
        })
    }
    const evaluatePosition = (edge) => {
    }
    const getAllPossibleMoves = (edgesList, player) => {
        return edgesList.filter(x => x.color === player);
    }


    const removeEdge = (currentedge) => {
        if (currentedge != null && currentedge.length != 0) {
            const edgeId = currentedge[0];
            const clickedEdged = edges.filter(edge => edge.id == edgeId);
            if (clickedEdged[0].color === currentPlayer) {
                removeDisconnectedEdges(edgeId);
                setCurrentPlayer(currentPlayer === "red" ? "blue" : "red");
                if (selectedMode === 'MACHINE') {
                    setPlayerTern('MACHINE')
                }
            } else {
                setopenWarning(true);
            }
        }
    };


    const removeDisconnectedEdges = (edgeId) => {

        const filteredEdges = edges.filter((edge) => edge.id !== edgeId)

        const visitedEdges = [];
        const floorNodes = nodes.filter(node => node.y === 400).map(node => node.id);


        const floorEdges = filteredEdges.filter(edge => floorNodes.includes(edge.from) || floorNodes.includes(edge.to));

        const BFS = (currentEdges) => {

            currentEdges.forEach(edge => {
                if (!visitedEdges.find(x => x.id === edge.id)) {
                    visitedEdges.push(edge);
                    const toNode = edge.to;
                    const fromNode = edge.from;
                    const nextLevelEdges = filteredEdges.filter(edge => edge.from === toNode || edge.to === toNode || edge.from === fromNode || edge.to === fromNode);
                    if (nextLevelEdges.length !== 0) {
                        BFS(nextLevelEdges)
                    }
                }
            })
        }

        BFS(floorEdges);
        setEdges((edges) => {
            return visitedEdges;
        }
        )
    }
    const handleSelectFigure = (selectedFigure) => {
        setGameStatus(GameStatus.Start)
        setEdges(selectedFigure.edges);
        setNodes(selectedFigure.nodes);
        setCurrentPlayer("red");
        setPlayerTern('HUMAN')
    };
    const graph = {
        nodes: nodes,
        edges: edges,
    };

    const options = {
        physics: {
            enabled: false, // Disable physics simulation
        },

        interaction: {
            dragNodes: false, // Disable node dragging
            dragView: false, // Disable panning
            zoomView: false,
        },

        edges: {
            smooth: {
                enabled: true,
                type: "dynamic",
                roundness: 0.5,
            },
        },
    };

    const containerStyle = {
        width: "100%", // 100% width

    };

    const paperStyle = {
        marginTop: "80px", // Increase the padding to make the Paper larger
        width: "100%", // 100% width

        position: 'relative'
    };

    const quitGame = () => {
        navigate('/');
    }
    const howToPlay = () => {
        setHowToPlayDialogVisibility(true)
    }
    const closeHowToPlayDialog = () => {
        setHowToPlayDialogVisibility(false)
    }
    const figuresContainerStyle = {
        marginBottom: '20px',
        maxHeight: "100px", // Adjust the max height as needed
        overflowY: "auto", // Enable vertical scrolling
    };
    const lineStyle = {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 400, // The specific Y point where you want the line
        borderTop: '1px solid #000', // You can customize the line's appearance here
    };

    const onModeSelection = (selection) => {
        alert(selection)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setopenWarning(false);
    };

    return (
        <div style={{ height: '50vh', alignItems: 'center', justifyContent: 'center' }}>
            <NavBar game={{ name: 'HAKENBUSH', started: gameStatus === GameStatus.Start }}></NavBar>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar anchorOrigin={{ vertical: 'center', horizontal: 'center' }} open={openWarning}
                    autoHideDuration={2000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                        This is {currentPlayer}'s turn.
                    </Alert>
                </Snackbar>
            </Stack>
            {gameStatus === GameStatus.End &&
                <GameResultDialog result={{ winner: winner, open: true }}></GameResultDialog>}
            {/* <GameModeSelector onSelection ={onModeSelection}></GameModeSelector> */}
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={8}>
                    <div style={containerStyle}>
                        {gameStatus !== GameStatus.Start &&
                            <FigureSelection onSelectFigure={handleSelectFigure} style={figuresContainerStyle} />}
                        <Divider></Divider>
                        {gameStatus === GameStatus.Start &&
                            <Paper disabled={true} elevation={3} style={paperStyle}>
                                <Typography variant="h6" color="textPrimary" gutterBottom style={{
                                    textAlign: 'center',
                                    padding: '16px',
                                    backgroundColor: '#EEEEEE',
                                    borderRadius: '8px'
                                }}>
                                    {selectedMode === 'MACHINE' && playerTurn === 'MACHINE' && <LoadingIcon />}
                                    <span style={{ fontWeight: 'bold', color: currentPlayer === 'red' ? '#FF5722' : '#2196F3' }}>
                                        Current Player is {currentPlayer}
                                    </span>. Please click the <span style={{
                                        fontWeight: 'bold',
                                        color: currentPlayer === 'red' ? '#FF5722' : '#2196F3'
                                    }}>
                                        {currentPlayer}
                                    </span> edge.
                                </Typography>
                                <Graph style={{ height: "30vh", borderBottom: '30px solid #EEEEEE ' }} disabled={true}
                                    key={uuid()}
                                    graph={graph} options={options}
                                    events={{ click: (event) => removeEdge(event.edges) }} />
                                <Box display="flex"
                                    justifyContent="space-between"
                                    alignItems="center">
                                    <Button variant="contained" style ={{ backgroundColor : "#373A40",color : "#DC5F00" }}onClick={quitGame}>
                                        Quit
                                    </Button>
                                    <Button variant="contained" style ={{ backgroundColor : "#373A40",color : "#DC5F00" }} onClick={howToPlay}>
                                        How to play
                                    </Button>
                                </Box>
                                <HowToPlay open={openhowToPlayDialog} handleClose={closeHowToPlayDialog}></HowToPlay>
                            </Paper>
                        }
                    </div>
                </Grid>
            </Grid>

        </div>

    );
};
