import React from 'react';
import Tile from './cell';
import './ultimate-tic-tac-toe.css';

const SubBoard = ({ tiles, onClick, isLocked, winner }) => {
  return (
    <div className="sub-board">
      {tiles.map((cell, index) => (
        <Tile key={index} value={cell} onClick={() => !isLocked && onClick(index, cell)} />
      ))}
      {winner && (
        <div className="board-winner-overlay full-board">
          <span className="winner-text" style={{ color: winner === 'X' ? '#DC5F00' : '#ffffff' }}>{winner}</span>
        </div>
      )}
    </div>
  );
};

export default SubBoard;
