import React, { useEffect, useState ,useContext} from 'react';
import Board from './board'; 
import './ultimate-tic-tac-toe.css';
import NavBar from "../../navbar/navbar";
import PlayerSelectionDialog from "../Hackenbush/playerModeSelectionDialog";
import { useNavigate } from 'react-router-dom';
import {GameModeContext} from "../Hackenbush/Provider/GameModeProvider";
import { showErrorDialog, closeErrorDialog } from  '../Common/error-dialog-util'
import  ErrorDialog from '../Common/error-dialog'
import WinTable from '../tik-tak-toe/win-table';
import {Grid,Container,Box,Typography} from '@mui/material';
import LoadingIcon from '../Hackenbush/LoadingIcon';


const useGameMode = () => useContext(GameModeContext);
const UltimateTicTacToe = () => {
  const initialBoard = Array(9).fill().map(() => Array(9).fill(null));
  const [boards, setBoards] = useState(initialBoard);
  const [boardWinners, setBoardWinners] = useState(Array(9).fill(null));
  const [gameStatus, setGameStatus] = useState("");
  const [gameTurn, setGameTurn] = useState("X");
  const [nextBoard, setNextBoard] = useState(null);
  const [isDialogOpen, handleGameModeDialogVisibility] = useState(false);
  const {selectedMode, handleModeSelect} = useGameMode();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [gameResult, setGameResult] = useState({XWinCount: 0, OWinCount: 0})

  const handleError = (message) => {
    showErrorDialog(setErrorDialogOpen, setErrorMessage, message);
  };

  const handleCloseDialog = () => {
    closeErrorDialog(setErrorDialogOpen);
  };

  const turnColor = gameTurn === 'X' ? '#DC5F00' : '#686D76';
  const navigate = useNavigate();

  useEffect(() => {
    handleGameModeDialogVisibility(true)
 
  }, []);

  useEffect(() => {
    if (selectedMode === 'MACHINE') {
      setGameTurn("X");
  }
  if (selectedMode === 'HUMAN') {
    setGameTurn("X");
  }

  }, [selectedMode]);

  useEffect(() => {
    if (selectedMode === 'MACHINE' && gameTurn === 'O') {
      setTimeout(() => {
        makeMachineMove();  // Call the machine move function

    },1000)
    }
  }, [gameTurn]);

  const checkBoardWinner = (board) => {
    const lines = [
      [0, 1, 2], [3, 4, 5], [6, 7, 8], // rows
      [0, 3, 6], [1, 4, 7], [2, 5, 8], // columns
      [0, 4, 8], [2, 4, 6]             // diagonals
    ];

    for (let line of lines) {
      const [a, b, c] = line;
      if (board[a] && board[a] === board[b] && board[a] === board[c]) {
        return board[a];
      }
    }
    return board.every(cell => cell !== null) ? 'Draw' : null;
  };

  const checkOverallWinner = (boardWinners) => {
    const lines = [
      [0, 1, 2], [3, 4, 5], [6, 7, 8], // rows
      [0, 3, 6], [1, 4, 7], [2, 5, 8], // columns
      [0, 4, 8], [2, 4, 6]             // diagonals
    ];

    for (let line of lines) {
      const [a, b, c] = line;
      if (boardWinners[a] && boardWinners[a] === boardWinners[b] && boardWinners[a] === boardWinners[c]) {
        return boardWinners[a];
      }
    }
    return boardWinners.every(winner => winner !== null) ? 'Draw' : null;
  };

  function closeDialog() {
    handleGameModeDialogVisibility(false)
    navigate('/');
}

  const handleCellClick = (boardIndex, cellIndex) => {
    if ((nextBoard !== null && nextBoard !== boardIndex) || boardWinners[boardIndex]) {
      handleError("Please select the correct cell.");
      return;
    }

    const newBoards = [...boards];
    const newBoardWinners = [...boardWinners];

    if (!newBoards[boardIndex][cellIndex]) {
      newBoards[boardIndex] = [...newBoards[boardIndex]];
      newBoards[boardIndex][cellIndex] = gameTurn;

      const boardWinner = checkBoardWinner(newBoards[boardIndex]);
      if (boardWinner && boardWinner !== 'Draw') {
        newBoardWinners[boardIndex] = boardWinner;
      }

      setBoards(newBoards);
      setBoardWinners(newBoardWinners);
      setGameTurn(gameTurn === 'X' ? 'O' : 'X');
      setNextBoard(newBoardWinners[cellIndex] ? null : cellIndex);

      const overallWinner = checkOverallWinner(newBoardWinners);
      if (overallWinner) {
        alert(`${overallWinner} wins the game!`);
        if(overallWinner === 'O') {
          setGameResult({...gameResult, OWinCount: gameResult.OWinCount + 1})
        }
        else if(overallWinner === 'X') {
          setGameResult({...gameResult, XWinCount: gameResult.XWinCount + 1})
        }
        resetBoard()
      }
    }
  };

  const resetBoard = () => {
    setBoards(initialBoard);
    setBoardWinners(Array(9).fill(null));
    setGameStatus('');
    setNextBoard(null);
    setGameTurn('X');
  };


  const makeMachineMove = () => {
    let boardIndex = nextBoard !== null ? nextBoard : findAvailableBoard();
    if (boardIndex === null) return; // No valid move if the board is unavailable

    let cellIndex = getBestMove(boardIndex);
    if (cellIndex === null) return; // No valid move if no cell available

    handleCellClick(boardIndex, cellIndex);
  };

  const findAvailableBoard = () => {
    // Find the first board that has no winner and has at least one empty cell
    for (let i = 0; i < 9; i++) {
      if (!boardWinners[i] && boards[i].some(cell => cell === null)) {
        return i;
      }
    }
    return null;
  };

  const getBestMove = (boardIndex) => {
    // First, check if the machine can win in this board
    let winningMove = findWinningMove(boardIndex, 'O');
    if (winningMove !== null) return winningMove;

    // Second, check if the opponent can win, and block them
    let blockingMove = findWinningMove(boardIndex, 'X');
    if (blockingMove !== null) return blockingMove;

    // Third, prioritize the center cell if available
    if (!boards[boardIndex][4]) return 4;

    // Finally, return any random available cell
    return findAvailableCell(boardIndex);
  };

  const findWinningMove = (boardIndex, player) => {
    const board = boards[boardIndex];
    const lines = [
      [0, 1, 2], [3, 4, 5], [6, 7, 8], // rows
      [0, 3, 6], [1, 4, 7], [2, 5, 8], // columns
      [0, 4, 8], [2, 4, 6]             // diagonals
    ];

    for (let line of lines) {
      const [a, b, c] = line;
      if (board[a] === player && board[a] === board[b] && !board[c]) return c;
      if (board[a] === player && board[a] === board[c] && !board[b]) return b;
      if (board[b] === player && board[b] === board[c] && !board[a]) return a;
    }
    return null;
  };

  const findAvailableCell = (boardIndex) => {
    // Find the first empty cell in the given board
    for (let i = 0; i < 9; i++) {
      if (!boards[boardIndex][i]) {
        return i;
      }
    }
    return null;
  }


  return (
    <div>
    <PlayerSelectionDialog open={isDialogOpen} onClose={closeDialog} onStart={() => handleGameModeDialogVisibility(false)} />
    <NavBar game={{ name: ' Ultimate Tic-Tac-Toe', started: false }} />
    <Container maxWidth="md">
    <Box sx={{ marginTop: 4 }}>
    <Typography
        variant="h6"
        gutterBottom
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          padding: '16px',
          backgroundColor: '#EEEEEE',
          color: turnColor,
          marginTop: '16px',
          marginBottom: '32px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        Current Player: {gameTurn}
      </Typography>
      {gameTurn === 'O' &&  selectedMode === 'MACHINE' && <LoadingIcon />}

      <Grid container spacing={2} justifyContent="center" alignItems="center" >
      <Grid item xs={12} sm={8} md={8} style={{ backgroundColor: '#EEEEEE' }}>
  <Box
    sx={{
      position: 'relative',
      width: '100%',
      paddingTop: '100%', // This maintains a 1:1 aspect ratio
      backgroundColor: '#EEEEEE',
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Board
        boards={boards}
        onCellClick={handleCellClick}
        boardWinners={boardWinners}
      />
    </Box>
  </Box>
  {gameStatus && (
    <Typography variant="h6" align="center" sx={{ marginTop: 2 }}>
      {gameStatus}
    </Typography>
  )}
  <ErrorDialog isOpen={errorDialogOpen} message={errorMessage} onClose={handleCloseDialog} />
</Grid>
        <Grid item xs={12} sm={4} md={4} style={{marginTop: '16px'}}>
          <WinTable gameResult={gameResult} />
        </Grid>
      </Grid>
    </Box>
  </Container>
  </div>
  );
};

export default UltimateTicTacToe;