import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { GameModeContext } from "../Games/Hackenbush/Provider/GameModeProvider";

import Hackenbush from '../../resources/games/Hackenbush.jpg';
import TikTakToe from '../../resources/games/tic-tac-toe.png';
import Battleship from '../../resources/games/Battleship.png';
import UltimateTicTacToe from '../../resources/games/Ultimate-tic-tac-toe.png';

import './home-css.css';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const useGameMode = () => useContext(GameModeContext);

const games = [
    { id: 1, name: 'Hackenbush', image: Hackenbush, route: '/hackenbush' },
    { id: 2, name: 'Ultimate Tic-Tac-Toe', image: UltimateTicTacToe, route: '/ultimate-tic-tac-toe' },
    { id: 3, name: 'Tic-Tak-Toe', image: TikTakToe, route: '/tiktaktoe' },
    { id: 3, name: 'Battle Ship', image: Battleship, route: '/battleship' }
];

const GameCard = ({ game, onClick }) => (
    <Grid item key={game.id} xs={10} sm={5} md={3}>
        <Card
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 8, // Add rounded corners
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add a shadow
                backgroundColor: '#DC5F00',
                border: '5px solid #EEEEEE',
                '&:hover': {
                    transform: 'scale(1.03)',
                    cursor: 'pointer',
                    animation: 'shine 1s infinite'
                },
            }}
            onClick={onClick}
        >
            <CardMedia
                component="div"
                sx={{
                    pt: '75%',
                    backgroundSize: 'cover',
                    backgroundImage: `url(${game.image})`,
                }}
                image={game.image}
            />
            <CardContent sx={{ flexGrow: 1 }} >
                <Typography gutterBottom variant="h5" component="h2">
                    {game.name}
                </Typography>
            </CardContent>
        </Card>
    </Grid>
);

export default function Home() {
    const navigate = useNavigate();
    const appBarStyle = {
        backgroundColor: '#373A40', // Change the background color to a suitable color
        borderBottom: '2px solid #DC5F00', // Add a bottom border
        color: '#DC5F00', // Text color
    };

    const { handleModeSelect } = useGameMode();

    useEffect(() => {
        handleModeSelect('')
    }, []);

    return (
        <div className="background">
            <AppBar position="relative" sx={appBarStyle}>
                <Toolbar>
                    <Typography variant="h6" color="inherit" noWrap>
                        Paper And Pencil Games.
                    </Typography>
                </Toolbar>
            </AppBar>
            <main>
                {/* Hero unit */}
                <Box
                    sx={{
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="lg" className="custom-container">
                        <h1 className="multi-color-text" style={{
    animation: 'colorChange 2s infinite'
  }}>
                            Paper And Pencil Games.
                        </h1>
                        <h4 align="center" className="responsive-text">
                            Classic Paper and Pencil Games – Now Online!
                        </h4>
                    </Container>
                </Box>
                <Container sx={{ py: 8 }} maxWidth="lg" className="custom-container">
                    {/* End hero unit */}
                    <Grid container spacing={2} justifyContent="center">
                        {games.map(game => (
                            <GameCard key={game.id} game={game} onClick={() => navigate(game.route)} />
                        ))}
                    </Grid>
                </Container>
            </main>
        </div>
    );
}