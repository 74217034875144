import React, { useContext, useEffect, useState } from "react";
import { Typography, Container, Grid, Card, CardContent, CardMedia } from "@mui/material";
import { figureEdges1, figureNodes1, figureEdges2, figureNodes2, figureEdges3, figureNodes3, figureEdges4, figureNodes4 } from "./figures-graph";
import figure1 from '../../../resources/figure1.png';
import figure2 from '../../../resources/figure2.png';
import figure3 from '../../../resources/figure3.png';
import figure4 from '../../../resources/figure4.png';
import PlayerSelectionDialog from './playerModeSelectionDialog';
import { GameModeContext } from "./Provider/GameModeProvider";

const hackenbushFigures = [
  { id: 1, name: "Figure 1", nodes: figureNodes1, edges: figureEdges1, image: figure1 },
  { id: 2, name: "Figure 2", nodes: figureNodes2, edges: figureEdges2, image: figure2 },
  { id: 3, name: "Figure 3", nodes: figureNodes3, edges: figureEdges3, image: figure3 },
  { id: 4, name: "Figure 4", nodes: figureNodes4, edges: figureEdges4, image: figure4 },
];

const useGameMode = () => useContext(GameModeContext);

export default function FigureSelection({ onSelectFigure }) {
  const { selectedMode } = useGameMode();
  const [selectedFigure, setSelectedFigure] = useState(null);
  const [isDialogOpen, handleGameModeDialogVisibility] = useState(false);

  useEffect(() => {
    if (selectedFigure != null) {
      onSelectFigure(selectedFigure);
    }
  }, [selectedMode]);

  const handleFigureSelect = (figure) => {
    handleGameModeDialogVisibility(true);
    setSelectedFigure(figure);
  };

  return (
    <div style={{ padding: '32px 0' }}>
      <PlayerSelectionDialog
        open={isDialogOpen}
        onClose={() => handleGameModeDialogVisibility(false)}
        onStart={() => {}}
      />
      <Typography
        variant="h6"
        gutterBottom
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          padding: '16px',
          backgroundColor: '#EEEEEE',
          color: '#373A40',
          marginTop: '16px',
          marginBottom: '32px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        Choose a Hackenbush Figure to Play
      </Typography>
      <Container>
        <Grid container justifyContent="center" spacing={2}>
          {hackenbushFigures.map((figure) => (
            <Grid item key={figure.id}>
              <Card
                onClick={() => handleFigureSelect(figure)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: '16px',
                  backgroundColor: selectedFigure?.id === figure.id ? '#DC5F00' : '#686D76',
                  border: selectedFigure?.id === figure.id ? '5px solid #DC5F00' : '5px solid #f0f8ff',
                  transition: 'transform 0.2s',
                  padding: '16px',
                  marginBottom: '16px',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    cursor: 'pointer',
                    border: '5px solid #EEEEEE',
                  },
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ width: 250, height: 200, borderRadius: '8px' }}
                  image={figure.image}
                />
                <CardContent sx={{ backgroundColor: '#373A40', borderRadius: '8px', padding: '8px' }}>
                  <Typography variant="h6" color="#DC5F00" align="center">
                    {figure.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}
